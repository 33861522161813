@import "_generated-font-mixins.scss";
.modal {
  overflow: hidden;
  width: 443px;
  padding: 20px;

  &__content {
    border-radius: 5px;

    & h2 {
      font-size: 18px;
      font-weight: bold;
    }
  }

  &__radio__group {
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    & li {
      &:not(:first-of-type) {
        margin-top: 6px;
      }
    }

    & label {
      display: flex;
      align-items: center;
      font-size: 13px;
      color: #333;

      & input[type='radio'] {
        margin-right: 10px;
      }
    }
  }
}

.textarea {
  resize: none;

  width: 100%;
  height: 100px;
  margin-top: 20px;
  padding: 7px 12px;

  font-size: 15px;
  vertical-align: top;

  border: 1px solid #ced3d3;
  border-radius: 4px;

  &:focus {
    border: 1px solid #b2b2b2;
  }

  &:required {
    border: 1px solid #ea3636;
  }
}

.button {
  margin-top: 15px;
  padding: 8px 20px;

  font-size: 15px;
  line-height: 1;
  color: #fff;
  letter-spacing: -0.42px;

  opacity: 1;
  background: #0058c5;
  border: none;
  border-radius: 4px;

  &:not(:disabled) {
    &:hover {
      opacity: 0.9;
      background: #0056b3;
      transition: opacity 0.2s;
    }
  }

  &:disabled {
    cursor: not-allowed;
    background: #ccc;
  }
}

.complain {
  padding: 30px 0 20px;

  & button {
    display: block;
    line-height: 40px;
  }
}
