@import "_generated-font-mixins.scss";
.drawer {
  pointer-events: none;

  position: fixed;
  z-index: 1002;
  bottom: 0;
  left: 0;

  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  width: 100%;
  height: 100%;

  opacity: 0;
  background-color: rgb(0 0 0 / 80%);

  &__title {
    & div {
      padding: 0 0 24px;
    }
  }
}

.content {
  position: relative;

  padding: 24px;

  text-align: left;

  background: #fff;
  border-radius: 8px 8px 0 0;

  transition: 0.4s all;

  & a {
    display: flex;
    height: 16px;
  }

  & i {
    cursor: pointer;

    position: absolute;
    top: -9px;
    right: -1px;

    font-size: 33px;
    color: #3333339e;
  }
}

.open {
  pointer-events: all;
  transform: scale(1);
  opacity: 1;
}

.title {
  padding: 17px 0 10px;

  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
  color: #333;
}

.show__number {
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;
  height: 50px;
  padding-left: 31px;

  line-height: auto;
  color: #2d82eb;
  text-decoration: none;

  &::before {
    @include icon-phone-contact;

    position: absolute;
    left: 0;
    font-size: 15px;
    color: rgb(45 130 235);
  }

  &__multiple:not(:first-of-type) {
    height: auto;
    margin-top: 16px;
    padding-top: 16px;

    line-height: 16px;

    background-position: left bottom;
    border-top: 1px solid #f0f1f4;
  }
}

.link {
  color: #015cc3;
}

.show__message {
  display: flex;
  flex-direction: row;
  align-items: center;

  height: 50px;
  padding: 17px 0;

  font-size: 15px;
  line-height: 50px;
  text-decoration: none;

  & a {
    color: #2d82eb;
    text-decoration: none;
  }

  & i {
    padding-right: 15px;
    font-size: 15px;
    color: #a0a0a0eb;
  }
}

.textarea {
  & textarea {
    resize: none;

    display: inline-block;

    width: 100%;
    height: 90px;
    margin-bottom: -2px;
    padding: 10px;

    font-size: 14px;

    appearance: none;
    border: 1px solid #e2e2e2;
    border-radius: 4px;
  }
}

.region__no__map {
  display: flex;
  align-items: center;

  margin: 0 10px 10px;
  padding: 17px 0;

  font-size: 15px;
  line-height: 17px;

  & i {
    margin-right: 20px;
    font-size: 18px;
    color: #a0a0a0eb;
  }
}

.region {
  position: relative;

  overflow: hidden;
  display: flex;
  align-items: center;

  margin: 0 10px 10px;
  padding: 17px 0;

  font-size: 15px;
  line-height: 17px;

  & i {
    margin-right: 20px;
    font-size: 18px;
    color: #a0a0a0eb;
  }

  &::after {
    content: '';

    position: absolute;
    top: 45%;
    right: 2px;
    transform: translateY(-50%) rotate(135deg);

    display: block;

    width: 11px;
    height: 11px;

    border-top: 2px solid #333;
    border-right: 2px solid #333;

    transition: 0.2s;
  }

  &.open::after {
    transform: translateY(-50%) rotate(-45deg);
  }
}

.textarea textarea::placeholder {
  font-size: 15px;
  line-height: 17px;
  color: #999;
}

.error {
  padding: 7px 0 0;
  color: red;
  text-align: center;
}

.error__message {
  position: absolute;
  right: 30px;
  bottom: 70px;

  font-size: 14px;
  color: #c01;
}
